// TODO: Este import habría que cargárselo seguramente
import { init as initHotelGallery } from '../../blocks/hotel_gallery/hotel_gallery'
import { openLoader } from '../../elements/wait_loading/wait_loading.js'
import on from '../../utils/events_delegation.js'

let galleryInitialized = false

const getThumbnailId = function (elem) {
  const dataId = elem.getAttribute('data-id')
  return dataId ? parseInt(dataId, 10) : null
}

const initIbGallery = function () {
  if (!galleryInitialized) {
    galleryInitialized = true
    import(
      /* webpackChunkName: 'ib_gallery' */
      `./ib_gallery`
    )
  }
}

function init() {
  on({
    eventName: 'click',
    selector: '[data-multi-modal-trigger]',
    handler(e) {
      e.preventDefault()
      const elem = e.target.closest('[data-multi-modal-trigger]')
      initIbGallery()
      openLoader()

      import(
        /* webpackChunkName: 'multi_modal' */
        `../multi_modal/multi_modal.js`
      )
        .then(blockJS => blockJS.openMultiModal($(elem)))
        .catch(() => {})
    },
    once: true,
  })

  // TODO: Este click no debería estar aquí. Solo sirve para abrir el modal desde el badge de star prestige. Este fichero no debería ser el responsable de hacer esto.
  on({
    eventName: 'click',
    selector: '.is-star-prestige',
    handler(e) {
      e.preventDefault()
      const elem = e.target
      initIbGallery()
      import(
        /* webpackChunkName: 'star_prestige' */
        `../../shared/star_prestige/star_prestige`
      )
        .then(blockJS => blockJS.openStarPrestige($(elem)))
        .catch(() => {})
    },
    once: true,
  })

  // If isHotel -> render hotel_gallery
  if (IB.currentPageType.isHotel()) {
    initHotelGallery()
  }
}

init()
