import { readCookie, setCookies } from '../../utils/cookie_manager'

const getHotelHeadquarters = function () {
  const cookies = []
  const previous_hotel_headquarter_origin = readCookie('hotel_headquarter_origin')

  // Gets hotel_headquarter_origin
  if (
    previous_hotel_headquarter_origin &&
    previous_hotel_headquarter_origin != '' &&
    previous_hotel_headquarter_origin != 'uninformed'
  ) {
    utag_data = $.extend({}, utag_data, { hotel_headquarter_origin: previous_hotel_headquarter_origin })
  } else {
    const hotel_headquarter_origin_data = document.querySelector('body').dataset.hotelHeadquarterOrigin
    utag_data = $.extend({}, utag_data, { hotel_headquarter_origin: hotel_headquarter_origin_data })

    cookies.push({ name: 'hotel_headquarter_origin', value: hotel_headquarter_origin_data })
  }

  // Gets hotel_headquarter_final
  const hotel_headquarter_final_data = document.querySelector('body').dataset.hotelHeadquarterFinal
  utag_data = $.extend({}, utag_data, { hotel_headquarter_final: hotel_headquarter_final_data })

  cookies.push({ name: 'hotel_headquarter_final', value: hotel_headquarter_final_data })
  setCookies(cookies)
}

getHotelHeadquarters()
