import { setCookies } from '../../utils/cookie_manager'

function getDeviceId() {
  const device_id = localStorage.getItem('indigitall.repository.DEVICE_ID')
  return device_id
}

function getDeviceType() {
  const device_type = localStorage.getItem('indigitall.repository.DEVICE_TYPE')
  return device_type
}

function storeDeviceDataInCookies(device_id, device_type) {
  const cookies = []

  if ($.cookie('idevice_id') != device_id) {
    cookies.push({ name: 'idevice_id', value: device_id })
  }
  if ($.cookie('idevice_type') != device_type) {
    cookies.push({ name: 'idevice_type', value: device_type })
  }
  if (cookies.length > 0) {
    setCookies(cookies)
  }
}

function loadDeviceDataToForm() {
  if ($('.device-id-first').length > 0) {
    const device_id = getDeviceId()
    const device_type = getDeviceType()

    storeDeviceDataInCookies(device_id, device_type)

    if (device_id == undefined || device_type == undefined) {
      // indigitall.deviceGet((device) => {
      //  // success function
      // },() => {
      //  // error function
      // });
    } else {
      const { first_element, second_element, third_element, fourth_element, fifth_element } =
        decomposeDeviceIdForWaf(device_id)

      $('.device-id-first').val(first_element)
      $('.device-id-second').val(second_element)
      $('.device-id-third').val(third_element)
      $('.device-id-fourth').val(fourth_element)
      $('.device-id-fifth').val(fifth_element)
      $('.device-type').val(device_type)
    }
  }
}

function decomposeDeviceIdForWaf(device_id) {
  const get_values = device_id.split('-')

  return {
    first_element: get_values[0],
    second_element: get_values[1],
    third_element: get_values[2],
    fourth_element: get_values[3],
    fifth_element: get_values[4],
  }
}

function sendDeviceData() {
  const device_id = getDeviceId()
  const device_type = getDeviceType()
  storeDeviceDataInCookies(device_id, device_type)

  if (device_id != undefined && device_id != null && device_type != undefined) {
    const { first_element, second_element, third_element, fourth_element, fifth_element } =
      decomposeDeviceIdForWaf(device_id)

    $.ajax({
      dataType: 'JSON',
      type: 'POST',
      url: '/ajax_contents/assign_device/',
      data: {
        device_first_element: first_element,
        device_second_element: second_element,
        device_third_element: third_element,
        device_fourth_element: fourth_element,
        device_fifth_element: fifth_element,
        device_id: true,
        device_type,
      },
      success(data) {
        if (data && data['success']) {
          const browsing_data = IB.browsing_sessions.getData()
          if (browsing_data != null && browsing_data['redis_values'] != null) {
            browsing_data['redis_values']['device_id'] = data['id']
            browsing_data['redis_values']['device_type'] = data['type']
            IB.browsing_sessions.saveData(browsing_data)
          } else {
            IB.browsing_sessions.storeHash({ device_id: data['id'], device_type: data['type'] })
          }
        } else {
          console.log('Error al asignar los datos de dispositivo')
        }
      },
      error() {
        console.log('Error al enviar los datos de dispositivo')
      },
    })
  }
}

window.IB.deviceDetector = {
  loadDeviceDataToForm,
  sendDeviceData,
}

$(function () {
  IB.deviceDetector.loadDeviceDataToForm()

  if (IB.browsing_sessions.getData() != null) {
    const device_type = IB.browsing_sessions.getData()['redis_values']['device_type']
    const logged_in = IB.browsing_sessions.getData()['redis_values']['logged_in']
    if (device_type === undefined && logged_in !== undefined) {
      IB.deviceDetector.sendDeviceData()
    }
  }
})
