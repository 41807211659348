import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { setAsyncDayjsLocale } from '../../../core/utils/locale'
import { closeLoader } from '../../../elements/wait_loading/wait_loading'
import fastbooking from '../../../shared/fastbooking/ibh_fastbooking'
import { updatePricesInfo } from '../../../shared/update_prices/update_prices'
import { setOnCachedPage } from '../../../utils/prevoius_page_cache'

dayjs.extend(customParseFormat)

setAsyncDayjsLocale()

dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

export const onPageReady = () => {
  updatePricesInfo()

  if ($.cookie('user_the_club') !== undefined) {
    $('.main-header-content').find('[data-visible-to-the-club-user="false"]').closest('li').remove()
  }

  const $js_reset_password = $('.js-reset-password')

  if ($js_reset_password.length && IB.currentDevice === 'mobile') {
    $('.fz-row.save').append($js_reset_password.closest('.fz-col'))
  }

  $('.formikation').find('input[type=checkbox], input[type=radio], select').not('.chosen-select').formikation()

  if ($('.payment-newsletter-container').children().length > 1)
    $('.payment-newsletter-container').addClass('with-newsletter')

  if ($('.form-wrapper form').length > 0) {
    $('.form-wrapper form').parsley()
  }

  $('.fancybox-video').fancybox({
    toolbar: false,
    smallBtn: true,
    type: 'iframe',
    baseClass: 'iframe-youtube',
    spinnerTpl: '<div role="status" aria-live="polite" class="fancybox-loading"></div>',
  })

  $('.fancybox').fancybox({
    padding: 0,
    maxWidth: 1000,
    autoDimensions: true,
    autoSize: true,
    showNavArrows: false,
    beforeClose() {
      if ($('.date-picker-wrapper').is(':visible')) {
        $('.date-picker-wrapper').hide()
      }
    },
  })

  $('[data-fancybox]').fancybox({
    width: 'auto',
    height: 'auto',
    autoSize: true,
    scrolling: 'hidden',
    autoDimensions: true,
    showNavArrows: false,
    helpers: {
      overlay: {
        locked: true,
      },
    },
    beforeClose() {
      if ($('.date-picker-wrapper').is(':visible')) {
        $('.date-picker-wrapper').hide()
      }
    },
    padding: 0,
    margin: [10, 10, 10, 10],
  })

  // fix/PY04776-590--gestionar-mis-reservas-datarangepicker
  // TODO I think all this conditional can be deleted because it is the same as in reservarion_management_form.js.

  if ($('.single-datepicker-cnt').length > 0) {
    const language = document.documentElement.getAttribute('data-js-lang')
    const date_format = $('html').attr('data-date-format')
    const start_of_week = $('html').attr('data-start-of-week')

    $('.single-datepicker').each(function () {
      const $el = $(this)
      const singleMonth = $el.data('single-month') === undefined ? 'auto' : true

      $el
        .dateRangePicker({
          autoClose: true,
          format: date_format,
          startOfWeek: start_of_week,
          language,
          startDate: $el.hasClass('complaint-datepicker') ? 0 : new dayjs().format(date_format),
          singleDate: true,
          singleMonth,
          showTopbar: false,
        })
        .on('datepicker-opened', function (ev, picker) {
          $el.prop('readonly', true)
          if ($el.hasClass('complaint-datepicker')) picker.relatedTarget.css('left', $el.offset().left)
        })
        .on('datepicker-first-date-selected', function (ev, picker) {
          $el.prop('readonly', false)
          $el.val(dayjs(picker).format(date_format))
        })
    })

    $('.single-datepicker-cnt > span').on('click', function (e) {
      e.stopPropagation()
      $(this).parent().find('.single-datepicker').data('dateRangePicker').open()
    })
  }

  if (IB.currentDevice === 'mobile') {
    $('[data-mobile-panel]').mobilepanel()
  }

  $('.footer-newsletter, .sidebar-newsletter-suscription, .custom-newsletter').parsley()

  /*
    On Safari browsers, previous pages are cached.
    setOnCachedPage restore the page by default.
  */
  setOnCachedPage(function () {
    if (document.querySelector('#wait-loading').classList.contains('is-open')) {
      // Removes loading state
      closeLoader()
    }
  })
}
$(function () {
  onPageReady()

  $(document).on('click', '.fancybox-iframe-link', function (e) {
    e.preventDefault()
    const $this = $(this)

    if ($this.hasClass('availability')) {
      var width = '1360px',
        height = '700px'
    } else if ($this.hasClass('agents')) {
      var width = '1000px',
        height = '542px'
    } else if ($this.hasClass('reset-password')) {
      var width = '600px',
        height = '400px'
    } else {
      var width = '462px',
        height = '417px'
    }

    $.fancybox.open({
      src: $this.attr('href'),
      // width: dimensions ? 800: 1200,
      type: 'iframe',
      toolbar: false,
      smallBtn: true,
      iframe: {
        css: {
          width,
          height,
        },
      },
      beforeClose() {
        if ($('.date-picker-wrapper').is(':visible')) {
          $('.date-picker-wrapper').hide()
        }
      },
    })
  })

  // Closes every open fancybox
  $(document).on('click', '[data-fancybox-close]', function (e) {
    $.fancybox.close()
  })
})

document.addEventListener('setPromocodeInFastbooking', e => {
  fastbooking.setPromocode(e.detail)
})

$(document)
  .on('click', '.burger-search', function (e) {
    e.preventDefault()
    $('#burgermenu-blocker').trigger('click')
  })
  .on('submit', '.js-login-form form', function () {
    $(this).closest('.block-form').find('.b-loader').removeClass('hidden')
  })
  .on('change', '.autoform', function () {
    $(this).trigger('submit')
  })
  .on('click', '[data-go]', function (e) {
    e.preventDefault()
    const target = this.hash || ''
    let offset = 0

    const $target = $(target)
    if ($target.length != 0) {
      offset = $target.offset().top
      if (!$target.is(':visible')) {
        e.preventDefault()
        offset = $target.offsetParent().offset().top
      }
      if (this.hash === '#availabity-dates') {
        $target.find('.btn-down').not('.active').trigger('click')
      }
    }

    $('html,body').animate({ scrollTop: offset }, 500)
  })
  .on('click', '[data-show-href]', function (e) {
    e.preventDefault()
    const $el = $(this)

    $($el.attr('href')).toggle()
    $el.toggleClass('active')
  })
  .on('click', '[data-show]', function (e) {
    e.preventDefault()
    const $el = $(this)

    if ($el.closest('.hotel-services-list').length) {
      $($el.attr('href')).toggleClass('hidden')
      $el.toggleClass('active')

      if ($el.hasClass('room-details')) {
        $el.attr('aria-expanded', $el.hasClass('active'))
      }

      const $hotel_services_list = $el.closest('.hotel-services-list')
      if (IB.currentDevice === 'mobile' && $hotel_services_list.hasClass('opened')) {
        $('html,body').animate({ scrollTop: $hotel_services_list.offset().top - 60 }, 500)
      }
      $hotel_services_list.toggleClass('opened')
      if ($el.hasClass('active')) $el.html($el.data('opened'))
      else $el.html($el.data('closed'))
    } else {
      $el.siblings($el.attr('href')).toggle()
      $el.toggleClass('active')
    }

    if ($el.closest('.filters-fastbooking-results').length) {
      $('.filters-fastbooking-results').toggleClass('active')
    }

    if ($(this).closest('.fancybox-inner').length) {
      if ($.fancybox.getInstance()) {
        $.fancybox.getInstance().update()
      }
    }

    if ($('.destination-index-header-module').find($el).attr('href') === '#destination-index-fb') {
      $('.destination-index-header-module').find('[data-show]').hide()
    }

    if ($el.attr('href') === '#offers-drop-down' && IB.currentDevice === 'mobile') {
      const el_right = $el.offset().left + $el.outerWidth()

      $('#offers-drop-down').css({
        right: 'auto',
        left: el_right - $('#offers-drop-down').outerWidth() + 10,
      })
    }

    if ($el.attr('id') === 'poi-categories-collapser') {
      $el.closest('.poi-categories-cnt').toggleClass('hidden-dropdown')
    }

    if ($el.attr('id') === 'poi-how-to-collapser') {
      $el.closest('.poi-how-to-cnt').toggleClass('hidden-dropdown')
    }
  })

  // Detect click outside map buttons element to hide dropdown
  .on('click', function (e) {
    const $element = $('.js-map-options .js-map-dropdown')
    const target = e.target

    $element.each(function () {
      const $el = $(this)
      if (!$el[0].contains(target) && !$el.hasClass('hidden-dropdown')) {
        $el.find('.selector.active').removeClass('active')
        $el.addClass('hidden-dropdown')
      }
    })
  })

  .on('click', '[data-show-sub-menu]', function (e) {
    e.preventDefault()
    const $submenu = $(this).next('div').length ? $(this).next('div') : $(this).next('div')
    $('[data-show-sub-menu]').not($(this)).removeClass('is-displayed')
    $('[data-out]:visible').not($submenu).hide()
    $submenu.toggle()
    $(this).toggleClass('is-displayed')
    if ($submenu.data('out') === undefined) $submenu.attr('data-out', true)
    if ($submenu.attr('id') === undefined) {
      let id = this.hash
      id = id.substring(1)
      $submenu.attr('id', id)
    }
  })

  .on('click', '[data-slider]', function (e) {
    e.preventDefault()
    $($(this).attr('href')).slideToggle('fast')
    $(this).toggleClass('active')
    if ($(this).closest('.fancybox-inner').length) {
      $.fancybox.getInstance().update()
    }
  })
  .on('mouseup', function (e) {
    const $container = $('[data-out]:visible')
    const $trigger = $(`[href="#${$container.attr('id')}"]`)

    if (!$container.length) return

    if (
      !$container.is(e.target) && // if the target of the click isn't the container...
      !$trigger.is(e.target) &&
      !$trigger.is($(e.target).closest('[data-show]')) &&
      $container.has(e.target).length === 0 && // ... nor a descendant of the container
      !$('.date-picker-wrapper').is(e.target) &&
      $('.date-picker-wrapper').has(e.target).length === 0
    ) {
      if ($container.attr('id') === 'people-popover') {
        $container.find('.close-people-popover').trigger('click')
      } else if ($(e.target).closest('.language-selector').length === 0) {
        $trigger.removeClass('active is-displayed')
        $container.hide()
        if ($('.destination-index-header-module').find($container).attr('id') === 'destination-index-fb') {
          $('.destination-index-header-module').find('[data-show]').show()
        }
      }
    }
  })

  .on('click', '.gallery', function (e) {
    e.preventDefault()

    const $el = $(this)
    const $slides = $el
      .closest('.card')
      .find('.swiper-lazy')
      .clone()
      .map(function () {
        return { src: $(this).attr('data-original') }
      })
      .toArray()
    const with_filter = $el.closest('.swiper-container').find('.with-filter').length ? 'with-filter' : ''

    $.fancybox.open($slides, {
      baseClass: with_filter,
    })
  })
  .on('click', '#activation-link', function (e) {
    e.preventDefault()
    $.ajax({
      dataType: 'script',
      type: 'GET',
      url: $(this).attr('href'),
    })
  })
  .on('paste', '#iberostar_api_user_password_repeat, #password_repeat', function (e) {
    e.preventDefault()
  })
