/**
 * Calculates the total height of an element, including padding, border, and margins.
 *
 * @param {HTMLElement} element - The element for which the total height will be calculated.
 * @returns {number} - The total height of the element including padding, border, and margins.
 */
export const getTotalHeight = element => {
  if (element === null) return

  const { paddingTop, paddingBottom, borderTopWidth, borderBottomWidth, marginTop, marginBottom } =
    window.getComputedStyle(element)

  // Calculate total height with margins
  // Indicate decimal system, which is base 10, to avoid the Linter
  const totalHeightWithMargins =
    element.offsetHeight +
    parseInt(paddingTop, 10) +
    parseInt(paddingBottom, 10) +
    parseInt(borderTopWidth, 10) +
    parseInt(borderBottomWidth, 10) +
    parseInt(marginTop, 10) +
    parseInt(marginBottom, 10)

  return totalHeightWithMargins
}
