import './tooltip.scss';

//
// Opens tooltip in desktop, popup in responsive
//
// In order to make the fancybox work in responsive
// the attribute desc_html presence is mandatory.
// This is a temporary condition as we will unify
// the funcionality with tooltip discount type's
// in near future.
function init($tooltip) {
  if (!$tooltip.length) return;
  if (IB.currentDevice === 'desktop' || !$tooltip.data('mobile-fancybox')) {
    init_desktop_tooltip($tooltip);
  } else if (IB.currentDevice !== 'desktop' && $tooltip.data('mobile-fancybox')) {
    (IB.currentDevice !== 'mobile') && $tooltip.closest('.filters-block').length ? init_desktop_tooltip($tooltip) : init_responsive_popup($tooltip);
  }
}

function get_attribute_if_available($el, attr) {
  return typeof $el.data(attr) === 'undefined' ? '' : $el.data(attr);
}

function init_desktop_tooltip($tooltip) {
  let type = $tooltip.data('tooltip');
  let title = get_attribute_if_available($tooltip, 'title');
  let desc = get_attribute_if_available($tooltip, 'desc');
  let desc_html = get_attribute_if_available($tooltip, 'desc-html');
  let position = get_attribute_if_available($tooltip, 'position');
  let id = get_attribute_if_available($tooltip, 'id');
  let $template = $('<div class="basic-tooltip ' + type + ' ' + position + '" role="tooltip"></div>');
  if (id) {
    $template.attr('id', id);
  }
  $template.empty();
  if (title) $template.append('<p class="tooltip-title">' + title + '</p>');
  if (desc && !desc_html) $template.append('<div class="tooltip-desc">' + desc + '</div>');
  if (!desc && desc_html) $template.append('<div class="tooltip-desc">' + $(desc_html).html() + '</div>');

  $tooltip.focus(function () {
    $tooltip.trigger('mouseenter');
  });
  $tooltip.blur(function () {
    $tooltip.trigger('mouseleave');
  });
  $tooltip.hover(function () {
    $tooltip.append($template);
  }, function () {
    $tooltip.find('.basic-tooltip').remove();
  });
  $tooltip.on('focus', function () {
    $tooltip.append($template);
  });
  $tooltip.on('blur', function () {
    $tooltip.find('.basic-tooltip').remove();
  });
  $tooltip.on('click', function (e) {
    if (e.target.href === undefined) e.preventDefault();
    e.stopImmediatePropagation();
  });
}

function init_responsive_popup($tooltip) {
  $tooltip.unbind('click');
  $tooltip.on('click', function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    let $this = $(this);
    let title = get_attribute_if_available($this, 'title');
    let desc = get_attribute_if_available($this, 'desc');
    let desc_html = get_attribute_if_available($this, 'desc-html');
    let $template = $('<div></div>');

    if (title) $template.append('<p class="block-title">' + title + '</p>');
    if (desc && !desc_html) $template.append('<div class="t-p">' + desc + '</div>');
    if (!desc && desc_html) $template.append('<div class="t-p">' + $(desc_html).html() + '</div>');

    $.fancybox.open($template, {
      beforeShow: function () {
        ScrollLock.on();
      },
      afterClose: function () {
        ScrollLock.off();
      }
    });
  });
}

function destroy_tooltip($tooltip) {
  $tooltip.off('focus');
  $tooltip.off('blur');
  $tooltip.off('mouseenter');
  $tooltip.off('mouseleave');
  $tooltip.off('click');
}

const Tooltip = {
  init: init,
  destroy: destroy_tooltip
};

window.IB.tooltip = Tooltip;

export default Tooltip;

$(function () {
  $('[data-tooltip]').each(function (index, element) {
    Tooltip.init($(element));
  });
});
