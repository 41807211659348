/*
  Executes this callback only on cached previous pages.
*/

export function setOnCachedPage(callback){
  window.addEventListener('pageshow', function(event) {
    if (event.persisted) {
      callback();
    }
  });
}
